.pages {
    &.empty-home-page {
        height: calc(100vh - 85px);
    }

    &.payment-management-page {
        height: calc(100vh - 85px);
    }

    &.term-and-condition-page,
    &.privacy-policy-page {
        .page-content-wrapper {
            box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
            border-radius: 25px;
            height: 80vh;
            overflow-y: auto;
            padding: 30px;

            &::-webkit-scrollbar {
                display: none;
            }

            h4 {
                font-weight: 600;
            }
        }
    }

    &.about-page {
        .wrapper {
            height: calc(100vh - 185px);
            overflow: auto;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        h1 {
            font-weight: 600;
        }

        .img-wrapper {
            border-radius: 25px;
            background: $color-bg;
        }

        p {
            font-size: 16px;
        }
    }

    &.contact-page {
        h1 {
            color: $color-green;
        }

        .left-wrapper {
            background-color: $color-bg;
            border-radius: 25px;
            height: 605px;
            display: flex;
            align-items: center;


            p {
                font-size: 16px;
            }


            .content-container {
                .box {
                    background-color: $color-white;
                    border-radius: 10px;
                    display: inline-block;
                }

                .wrapper {
                    background-color: $color-bg;
                    border-radius: 25px;
                }

                .icon-wrapper {
                    display: inline-block;
                    background-color: $color-grey;
                    border-radius: 5px;
                    width: 35px;
                    line-height: 32px;
                    height: 35px;
                    min-width: 35px;
                    text-align: center;
                    vertical-align: middle;
                }

                p {
                    color: $color-dark-grey;
                    font-size: 14px;
                }
            }

            .logo-container {
                .box {
                    background-color: $color-white;
                    border: 1px solid $color-border;
                    width: 55px;
                    height: 55px;
                    min-width: 55px;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .right-wrapper {
            height: 605px;
            display: flex;
            box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
            border-radius: 25px;

            textarea {
                resize: none;
                height: 175px;

                &::-webkit-scrollbar {
                    display: none;
                }

                &::placeholder {
                    color: $color-dark-grey;
                }
            }

            .box {

                .form-floating {
                    position: relative;

                    textarea {
                        height: 150px;
                        resize: none;
                    }

                    input {
                        transition: 0.3s ease all;

                        &:hover,
                        &:focus {
                            ~.icon-wrapper {
                                background-color: $color-green;

                                .icon {
                                    color: $color-white;
                                }
                            }
                        }
                    }

                    .icon-wrapper {
                        transition: 0.3s ease all;
                        position: absolute;
                        top: 12px;
                        left: 12px;
                        min-width: 35px;
                        min-height: 35px;
                        background-color: $color-grey;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;

                        .icon {
                            transition: 0.3s ease all;
                            color: $color-green;
                            font-size: 14px;
                        }
                    }

                    .icon-wrapper-right {
                        position: absolute;
                        top: 13px;
                        right: 12px;
                        min-width: 15px;
                        min-height: 35px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;

                        .icon {
                            color: $color-green;
                        }
                    }
                }
            }
        }
    }

    &.statistics-and-analytics-page,
    &.post-list-page {
        .folder-box {
            cursor: pointer;

            span {
                font-weight: 700;
                @extend .Nord-Regular;
                font-size: 14px;
            }
        }

        a {
            text-decoration: none;
            color: unset;
        }
    }

    &.error-page {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .btn-solid {
            max-width: 325px;
            margin: auto;
        }
    }

    &.view-all-message-page {
        .search-wrapper {
            .search-input {
                background-color: $color-bg-light;
                width: 100%;
                background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.57982 19.0698C4.34536 19.0698 0.0836792 14.7907 0.0836792 9.53491C0.0836792 4.27908 4.34536 0 9.57982 0C14.8143 0 19.076 4.27908 19.076 9.53491C19.076 14.7907 14.8143 19.0698 9.57982 19.0698ZM9.57982 1.39535C5.10505 1.39535 1.47336 5.05118 1.47336 9.53491C1.47336 14.0186 5.10505 17.6745 9.57982 17.6745C14.0546 17.6745 17.6863 14.0186 17.6863 9.53491C17.6863 5.05118 14.0546 1.39535 9.57982 1.39535Z' fill='%23515151'/%3E%3Cpath d='M19.3075 20.0001C19.1315 20.0001 18.9555 19.935 18.8165 19.7954L16.9636 17.935C16.6949 17.6652 16.6949 17.2187 16.9636 16.9489C17.2323 16.6791 17.677 16.6791 17.9456 16.9489L19.7985 18.8094C20.0672 19.0791 20.0672 19.5257 19.7985 19.7954C19.6596 19.935 19.4835 20.0001 19.3075 20.0001Z' fill='%23515151'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-position: 95% 11px;
                padding: 10px 53px 10px 20px;
            }
        }

        .thread-container {
            background-color: $color-white;
            border-radius: 25px;
            padding: 30px 25px;
            box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
            height: calc(100vh - 180px);

            .no-msg-box {
                height: 100%;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .msg-icon {
                    color: $color-light-grey;
                    font-size: 5rem;
                }

                .no-msg-text {
                    display: block;
                    font-size: 1.5rem;
                    color: $color-light-grey;
                }
            }

            .thread-wrapper {
                height: calc(100vh - 365px);
                overflow-y: auto;
            }
        }

        .message-container {
            background-color: $color-white;
            border-radius: 25px;
            padding: 30px 25px;
            box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
            height: calc(100vh - 180px);

            .no-msg-box {
                height: 100%;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .msg-icon {
                    color: $color-light-grey;
                    font-size: 5rem;
                }

                .no-msg-text {
                    display: block;
                    font-size: 1.5rem;
                    color: $color-light-grey;
                }
            }

            .dropdown {
                .dropdown-menu {
                    padding: 0 9px;
                    border-radius: 10px;
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                    border: none;
                }

                .dropdown-toggle {
                    width: 100%;
                    height: 100%;

                    svg {
                        margin-top: -5px;
                    }

                    &::after {
                        display: none;
                    }

                    &.btn {
                        &.btn-primary {
                            padding: 0;
                            background: transparent;
                        }
                    }
                }

                .dropdown-item {
                    padding: 11px;
                    @extend .Outfit-Regular;
                    font-size: 14px;
                    color: black;

                    &:hover,
                    &:active {
                        background-color: transparent;
                    }

                    &:last-child {
                        color: $color-dark-grey;
                    }
                }
            }

            .file-box {
                background-color: $color-light-grey;
                padding: 10px;
                border-radius: 4px;
                margin-top: 10px;
                margin-bottom: 10px;

                .file-name {
                    width: 300px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 14px;
                    font-weight: 600;
                    margin-right: 12px
                }
            }

            .input-wrapper {
                margin-top: 15px;

                .box {
                    padding: 7px;
                    background-color: $color-bg-light;
                    border-radius: 5px;
                }

                .btn-solid {
                    background-color: transparent !important;
                }

                .form-control {
                    padding: 14px 0 0 0;
                    margin: 0 12px !important;
                    font-size: 12px;
                    border: none;
                    resize: none;
                    background-color: transparent;
                    border-radius: 0 !important;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    &:hover,
                    &:focus {
                        border: none;
                    }
                }

                .camera-icon-box {
                    margin-top: -2px;
                }

                .icon-wrapper {
                    min-width: 45px;
                    background-color: $color-green;
                    width: 45px;
                    height: 45px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px;
                    cursor: pointer;
                }

                .btn-solid {
                    width: 45px;
                    height: 45px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px;
                    padding: 0 !important;
                }
            }

            .message-wrapper {
                height: calc(100vh - 385px);
                overflow-y: auto;
                padding-right:10px;

                &.with-file {
                    height: calc(100vh - 435px);
                }
            }
        }

        .profile-container {
            background-color: $color-white;
            border-radius: 25px;
            padding: 30px 25px;
            box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
            height: calc(100vh - 180px);
            text-align: center;

            a {
                @extend .Outfit-Regular;
                font-size: 16px;
                font-weight: 400;
                text-decoration: none;
            }

            .img-wrapper {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                overflow: hidden;
                margin-bottom: 10px !important;
                margin: auto;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }
        }
    }

}