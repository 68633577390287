/* XXL devices */
@media only screen and (max-width: 1400px) {
    .setting-sidebar {
        height: 72vh;
    }

    .massonary-grid {
        .massonary-card {
            &.even {
                .post-img {
                    height: 200px;
                }
            }

            &.odd {
                .post-img {
                    height: 300px;
                }
            }
        }
    }
}

/* XL devices */
@media only screen and (max-width: 1200px) {
    .post-card {
        .img-wrapper {
            height: 320px;
        }
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
    .auth-layout {
        .auth-card {

            &.account-verification-form,
            &.password-verification-form {
                .otp-input {
                    input {
                        height: 45px;
                    }
                }
            }
        }
    }

    .folder-tabs,
    .added-post-card {
        .btn-solid {
            &.done-btn {
                padding: 1rem;
            }
        }
    }

    .massonary-grid {
        .massonary-card {
            &.even {
                .post-img {
                    height: 200px;
                }
            }

            &.odd {
                .post-img {
                    height: 310px;
                }
            }
        }
    }

    .post-card {
        .img-wrapper {
            height: 330px;
        }
    }
}

/* Medium devices */
@media only screen and (max-width: 768px) {
    .auth-layout {
        .auth-card {

            &.account-verification-form,
            &.password-verification-form {
                .otp-input {
                    input {
                        height: 50px;
                    }
                }
            }
        }
    }

    .folder-tabs,
    .added-post-card {
        .nav {
            &.nav-pills {
                .nav-link {
                    margin: 0;
                }
            }
        }
    }

    .massonary-grid {
        .massonary-card {
            &.even {
                .post-img {
                    height: 165px;
                }
            }

            &.odd {
                .post-img {
                    height: 275px;
                }
            }
        }
    }

    .post-card {
        .img-wrapper {
            height: 285px;
        }
    }
}

@media screen and (max-width: 680px) {
    .post-card {
        .img-wrapper {
            height: 235px;
        }
    }

    .massonary-grid {
        .massonary-card {
            &.even {
                .post-img {
                    height: 150px;
                }
            }

            &.odd {
                .post-img {
                    height: 250px;
                }
            }
        }
    }
}

/* Small devices */
@media only screen and (max-width: 576px) {

    .post-detail-card {
        .img-list-view-wrapper {
            .list-wrapper {
                h4 {
                    font-size: 16px;
                }
            }

            ul {
                li {
                    font-size: 14px;
                }
            }

            .img-wrapper {
                width: 80px;
                height: 80px;
                min-width: 80px;
            }
        }
    }

    .theme-popup {
        &.notification-popup {
            .modal-content {
                max-width: 100%;
                transform: unset;
            }

            .notification-wrapper {
                .box {
                    .btn-solid {
                        padding: 8px 16px;
                    }
                }
            }
        }

        &.message-popup {
            .modal-content {
                max-width: 100%;
                transform: unset;
            }
        }

        &.profile-popup {
            .modal-content {
                max-width: 100%;
                transform: unset;
            }
        }

        &.calendar-popup {
            .react-calendar__month-view {
                font-size: 12px !important;
            }

            .react-calendar__tile--now {
                border-radius: 0;
            }

            .react-calendar__month-view__days {
                .react-calendar__tile {
                    &:hover {
                        border-radius: 0;
                    }
                }

                button {
                    height: auto;
                }

                .react-calendar__tile--active {
                    border-radius: 0;
                }

                .theme-popup.month-filter-popup .react-calendar__month-view__days .react-calendar__tile--now {
                    border-radius: 0;
                }
            }

            .time-picker-wrapper {
                .ampm-box {
                    label {
                        padding: 0.25rem 0.8rem;
                    }
                }
            }
        }

        &.calendar-popup {
            .modal-dialog {
                max-width: 100%;
            }
        }

        &.filter-popup {
            .modal-dialog {
                max-width: 100%;
            }
        }

        &.topup-popup,
        &.domain-verification-complete-popup,
        &.change-domain-popup,
        &.add-bank-account-popup,
        &.withdrawl-popup,
        &.add-folder-popup,
        &.block-alert-popup,
        &.unblock-alert-popup,
        &.delete-account-popup,
        &.delete-comment-popup,
        &.logout-popup {
            .modal-dialog {
                max-width: 100%;
            }
        }
    }

    .edit-profile-form {
        .px-40 {
            padding: 0 0;
        }
    }

    .collection-privacy-form,
    .change-password-form {
        padding: 30px 25px;
    }

    .change-domain-card,
    .payment-management-card,
    .create-post-box,
    .edit-profile-form,
    .block-user-card,
    .create-edit-post-form {
        width: 90%;
    }

    .folder-tabs,
    .added-post-card {
        .nav {
            &.nav-pills {
                justify-content: start;
            }
        }
    }

    .stats-card {
        .btn-solid {
            width: 100%;
        }

        .box {
            width: 100%;

            span {
                font-size: 12px;
            }
        }

        .icon-wrapper {
            min-width: 60px;
            width: 60px;
            height: 60px;
        }
    }

    .theme-tabs {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .nav-item {
            &:nth-child(odd) {
                border-left: none;
            }

            &:last-child {
                border-left: none;
            }

            &:nth-child(1) {
                border-left: none !important;
            }

            &:nth-child(2) {
                border-left: none;
            }
        }
    }

    .massonary-grid {
        .massonary-card {
            .profile-img-wrapper {
                min-width: 30px;
                width: 30px;
                height: 30px;
            }

            &.even {
                .post-img {
                    height: 180px;

                    .video-thumbnail {

                        .icon {
                            font-size: 2.5rem;
                        }
                    }
                }
            }

            &.odd {
                .post-img {
                    height: 270px;

                    .video-thumbnail {
                        .icon {
                            font-size: 2.5rem;
                        }
                    }
                }
            }
        }
    }

    .search-popup {
        .mt-1-5 {
            margin-top: 0.15rem;
        }

        .search-wrapper {
            .time-icon-button {
                transform: translateY(-1px);
            }

            .search-box {
                .form-control {
                    padding: 8px 15px 5px 0 !important;
                    background-image: none;
                }
            }
        }
    }

    .auth-card {
        padding: 30px 25px;

        &.select-role-form {
            .wrapper {
                label {
                    padding: 1.5rem 1rem;
                }

                .form-check-input {
                    right: 1rem;
                }
            }
        }
    }

    .rollup-card {
        .rollup-card-details {
            flex-direction: column;
            align-items: flex-start;

            .main-rollup-card-img {
                min-width: 100%;
                height: auto;

                img {
                    object-fit: unset;
                }
            }
        }
    }

    .post-card {
        .img-wrapper {
            height: 260px;
        }
    }

    .view-profile-card {
        .comming-request-wrapper {
            .btn-solid {
                padding: 9px 12px;
            }
        }
    }
}

/* Extra small devices */
@media only screen and (max-width: 460px) {
    .post-detail-card {
        .img-list-view-wrapper {
            .list-wrapper {
                h4 {
                    width: 200px;
                    font-size: 14px;
                }
            }

            ul {
                li {
                    font-size: 12px;
                }
            }

            .img-wrapper {
                width: 60px;
                height: 65px;
                min-width: 60px;
            }
        }
    }

    .auth-layout {
        .auth-card {

            &.account-verification-form,
            &.password-verification-form {
                .otp-input {
                    input {
                        height: 50px;
                    }
                }
            }
        }
    }

    .massonary-grid {
        .massonary-card {
            &.even {
                .post-img {
                    height: 160px;
                }
            }

            &.odd {
                .post-img {
                    height: 225px;
                }
            }
        }
    }

    .post-card {
        .img-wrapper {
            height: 220px;
        }
    }

    .view-profile-card {
        .comming-request-wrapper {
            .new-box {
                width: 100%;
                justify-content: center !important;
                flex-direction: column !important;
            }

            .btn-wrapper{
                margin-top: 16px;
            }
        }
    }
}

/* Extra small devices */
@media only screen and (max-width: 400px) {
    .auth-card{
        width:100%;
    }
    
    .post-detail-card {
        .img-list-view-wrapper {
            .list-wrapper {
                h4 {
                    width: 160px;
                }
            }
        }
    }


    .massonary-grid {
        .massonary-card {
            &.even {
                .post-img {
                    height: 130px;
                }
            }

            &.odd {
                .post-img {
                    height: 220px;
                }
            }
        }
    }

    .post-card {
        .img-wrapper {
            height: 190px;
        }
    }
}

/* Extra small devices */
@media only screen and (max-width: 375px) {
    .auth-card {
        &.select-role-form {
            .wrapper {
                label {
                    font-size: 14px;
                }
            }
        }
    }

    .view-profile-card {
        &.view-myprofile-card {
            .btn-solid {
                &.follow-btn {
                    padding: 9px 30px;
                }
            }
        }
    }

    .massonary-grid {
        .massonary-card {
            &.even {
                .post-img {
                    height: 120px;
                }
            }

            &.odd {
                .post-img {
                    height: 200px;
                }
            }
        }
    }
}

/* Extra small devices */
@media screen and (max-width: 350px) {

    .folder-tabs,
    .added-post-card {
        .nav {
            &.nav-pills {
                .nav-item {
                    width: 50%;
                }

                .nav-link {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }

    .massonary-grid {
        .massonary-card {
            &.even {
                .post-img {
                    height: 115px;
                }
            }

            &.odd {
                .post-img {
                    height: 180px;
                }
            }
        }
    }

    .post-card {
        .img-wrapper {
            height: 170px;
        }
    }
}

@media only screen and (max-width:354px) {
    .theme-popup {
        &.share-popup {
            .content-container {
                .wrapper {
                    justify-content: space-between;
                }
            }
        }
    }

    .user-thread {      
        .wrap{
          width: 140px;
        }
    }
}