.theme-popup {
    .modal-content {
        border-radius: 25px;
    }

    .modal-body {
        padding: 30px 25px;
    }

    .btn-transparent {
        border: none !important;
        @extend .Nord-Regular;
        font-weight: 700;
        color: $color-dark-grey;

        &:focus,
        &:hover {
            border: none !important;
        }

        &:first-child:active {
            border: none !important;
        }
    }

    &.change-domain-popup {
        .form-floating {
            position: relative;

            .form-select {
                background-image: url("data:image/svg+xml,%0A%3Csvg width='5' height='6' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.55048 5.44972L12 0.000199747L5.68447 0.000200977L-2.48173e-07 0.000200748L5.44952 5.44972C5.75354 5.75374 6.24646 5.75374 6.55048 5.44972Z' fill='%23101010'/%3E%3C/svg%3E%0A");
                background-size: 12px;
                background-position-y: 53%;
            }

            .form-select,
            input {
                padding-left: 61px;
                transition: 0.3s ease all;

                &:hover,
                &:focus {
                    ~.icon-wrapper {
                        background-color: $color-green;

                        svg {
                            fill: $color-white;

                            path {
                                fill: $color-white;
                            }
                        }
                    }
                }
            }

            label {
                top: 2px;
                padding-left: 62px;
                left: 6px;
            }

            .icon-wrapper {
                transition: 0.3s ease all;
                position: absolute;
                top: 12px;
                left: 12px;
                min-width: 35px;
                height: 35px;
                background-color: $color-grey;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                .icon {
                    transition: 0.3s ease all;
                    color: $color-green;
                    font-size: 14px;
                }
            }

            .icon-wrapper-right {
                position: absolute;
                top: 13px;
                right: 12px;
                min-width: 15px;
                min-height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                .icon {
                    color: $color-green;
                }
            }
        }

        .form-floating>.form-control:not(:placeholder-shown)~label {
            left: 6px;
        }

        .form-floating>.form-control:not(:placeholder-shown) {
            padding-top: 11px;
        }

        label {
            font-size: 14px;
        }
    }

    &.share-popup {
        .react-share__ShareButton {
            overflow: hidden;
            border-radius: 10px;
        }

        .copy-wrapper {
            border: 1px solid $color-border;
            border-radius: 10px;
            padding: 10px;
            margin: 5px;

            .input-share {
                border: none;
                outline: none;
                font-size: 16px;
                margin-right: 14px;
            }
        }

        .btn-copy {
            font-size: 14px;
            background-color: $color-black;
            color: $color-white;
        }
    }

    &.select-role-popup {
        .auth-card {
            box-shadow: none;
            width: 100%;
        }
    }

    &.profile-popup {
        .modal-content {
            max-width: 432px;
            transform: translateX(55px);
        }

        .cursor {
            cursor: pointer;
        }

        .modal-dialog {
            margin-right: 10px;
        }

        .modal-body {
            .times-icon {
                cursor: pointer;
            }

            .folder-icon {
                color: $color-deep-grey;
                font-size: 1.1rem;
            }

            .form-check-input {
                width: 36px;
                height: 20px;
                background-color: #e0e0e0 !important;
                background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23FFFF'/%3E%3C/svg%3E");

                &:focus {
                    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23FFFF'/%3E%3C/svg%3E");
                }

                &:checked {
                    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23101010'/%3E%3C/svg%3E");
                }
            }

            .profile-wrapper {
                min-width: 100px;
                width: 100px;
                height: 100px;
                border-radius: 50%;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    object-fit: cover;
                    object-position: center;
                }
            }

            span {
                font-size: 14px;
                cursor: pointer;
                color: $color-black;
            }

            a {
                @extend .Outfit-Regular;
                text-decoration: none;
                font-weight: 400;
            }

            .link-container {
                ul {
                    margin-bottom: 0;
                    padding: 0;
                    height: 375px;
                    overflow-y: auto;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    li {
                        list-style: none;
                    }
                }

                a {
                    color: $color-black;

                    &.active {
                        span {
                            font-weight: 600;
                        }
                    }
                }

                .icon-wrapper {
                    background-color: $color-grey;
                    width: 35px;
                    min-width: 35px;
                    height: 35px;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    &.report-user-popup {
        .text-grey {
            color: $color-dark-grey !important;
        }

        ul {
            padding: 0;

            li {
                list-style: none;

                .btn-transparent {
                    @extend .Outfit-Regular;
                    font: 14px;
                    color: $color-black;

                    &:disabled {
                        background: transparent;
                    }
                }
            }
        }
    }

    &.topup-popup,
    &.domain-verification-complete-popup,
    &.change-domain-popup,
    &.add-bank-account-popup,
    &.withdrawl-popup,
    &.add-folder-popup {
        .times-icon-wrapper {
            cursor: pointer;
        }

        input {
            font-weight: 600;
        }

        .content-container {
            label {
                font-size: 14px;
                font-weight: 400;
                color: $color-dark-grey;
                @extend .Outfit-Regular;
            }
        }
    }

    &.filter-popup {
        .modal-dialog {
            max-width: 432px;
        }

        .times-icon-wrapper {
            cursor: pointer;
        }

        label {
            text-align: center;
            width: 100%;
            font-size: 14px;
            font-weight: 400;
            padding: 10px 0;
            border-radius: 5px;
            color: $color-dark-grey;
            @extend .Outfit-Regular;
        }

        label:has(input:checked) {
            background: $color-deep-grey;
            color: $color-white;
        }
    }

    &.notification-popup {
        .modal-content {
            max-width: 432px;
            transform: translateX(55px);
        }

        .modal-dialog {
            margin-right: 10px;
        }

        .text-grey {
            color: $color-dark-grey !important;
        }

        .times-icon-wrapper {
            cursor: pointer;
        }

        h3 {
            span {
                color: $color-deep-grey;
                font-size: 18px;
                font-weight: 700;
                margin: 0;
                @extend .Nord-Regular;
            }
        }

        .search-wrapper {
            .search-input {
                background-color: $color-bg-light;
                width: 100%;
                background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.57982 19.0698C4.34536 19.0698 0.0836792 14.7907 0.0836792 9.53491C0.0836792 4.27908 4.34536 0 9.57982 0C14.8143 0 19.076 4.27908 19.076 9.53491C19.076 14.7907 14.8143 19.0698 9.57982 19.0698ZM9.57982 1.39535C5.10505 1.39535 1.47336 5.05118 1.47336 9.53491C1.47336 14.0186 5.10505 17.6745 9.57982 17.6745C14.0546 17.6745 17.6863 14.0186 17.6863 9.53491C17.6863 5.05118 14.0546 1.39535 9.57982 1.39535Z' fill='%23515151'/%3E%3Cpath d='M19.3075 20.0001C19.1315 20.0001 18.9555 19.935 18.8165 19.7954L16.9636 17.935C16.6949 17.6652 16.6949 17.2187 16.9636 16.9489C17.2323 16.6791 17.677 16.6791 17.9456 16.9489L19.7985 18.8094C20.0672 19.0791 20.0672 19.5257 19.7985 19.7954C19.6596 19.935 19.4835 20.0001 19.3075 20.0001Z' fill='%23515151'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-position: 95% 11px;
                padding: 10px 53px 10px 20px;
            }
        }

        .notification-wrapper {
            height: 600px;
            overflow-y: auto;

            .text-blue{
                color: $color-blue;
            }

            &::-webkit-scrollbar {
                display: none;
            }

            a {
                text-decoration: none;
                color: unset;
            }

            .box {
                .profile-wrapper {
                    width: 50px;
                    height: 50px;
                    min-width: 50px;
                    border-radius: 50%;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        object-fit: cover;
                        object-position: center;
                    }
                }

                small {
                    font-size: 12px;
                    color: $color-dark-grey;
                }

                h6 {
                    margin-top: 17px;
                    font-weight: 600;
                    font-size: 13px;

                    span {
                        font-size: 13px;
                        color: $color-blue;
                        font-weight: 400;
                    }
                }

                .btn-solid {
                    width: auto;
                    padding: 10px 20px;
                    font-size: 12px;
                    font-weight: 500;

                    &.green {
                        background-color: $color-deep-grey;
                    }

                    &.red {
                        background-color: $color-red;
                    }
                }

                .custom-position {
                    position: relative;

                    .btn-wrapper {
                        margin-top: 10px;
                    }

                    small {
                        position: absolute;
                        right: 0;
                        top: 0;
                    }
                }
            }
        }
    }

    &.message-popup {
        .modal-content {
            max-width: 432px;
            transform: translateX(55px);
        }

        .modal-dialog {
            margin-right: 10px;
        }

        .times-icon-wrapper {
            cursor: pointer;
        }

        .text-grey {
            color: $color-dark-grey !important;
        }

        h3 {
            span {
                color: $color-deep-grey;
                font-size: 18px;
                font-weight: 700;
                margin: 0;
                @extend .Nord-Regular;
            }
        }

        .search-wrapper {
            .search-input {
                background-color: $color-bg-light;
                width: 100%;
                background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.57982 19.0698C4.34536 19.0698 0.0836792 14.7907 0.0836792 9.53491C0.0836792 4.27908 4.34536 0 9.57982 0C14.8143 0 19.076 4.27908 19.076 9.53491C19.076 14.7907 14.8143 19.0698 9.57982 19.0698ZM9.57982 1.39535C5.10505 1.39535 1.47336 5.05118 1.47336 9.53491C1.47336 14.0186 5.10505 17.6745 9.57982 17.6745C14.0546 17.6745 17.6863 14.0186 17.6863 9.53491C17.6863 5.05118 14.0546 1.39535 9.57982 1.39535Z' fill='%23515151'/%3E%3Cpath d='M19.3075 20.0001C19.1315 20.0001 18.9555 19.935 18.8165 19.7954L16.9636 17.935C16.6949 17.6652 16.6949 17.2187 16.9636 16.9489C17.2323 16.6791 17.677 16.6791 17.9456 16.9489L19.7985 18.8094C20.0672 19.0791 20.0672 19.5257 19.7985 19.7954C19.6596 19.935 19.4835 20.0001 19.3075 20.0001Z' fill='%23515151'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-position: 95% 11px;
                padding: 10px 53px 10px 20px;
            }
        }

        .view-all-box {
            border-top: 1px solid $color-border;

            a {
                font-size: 16px;
                color: $color-dark-grey;
                font-weight: 700;
                @extend .Nord-Regular;
                text-decoration: none;
            }
        }

        .no-msg-box {
            text-align: center;
            height: 580px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .msg-icon {
                color: $color-light-grey;
                font-size: 5rem;
            }

            .no-msg-text {
                display: block;
                font-size: 1.5rem;
                color: $color-light-grey;
            }
        }

        .message-wrapper {
            height: 580px;
            overflow-y: auto;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &.calendar-popup {
        .modal-dialog {
            max-width: 432px;
        }

        .times-icon-wrapper {
            cursor: pointer;
        }

        .mb-10 {
            margin-bottom: 10px;
        }

        .react-calendar {
            border: none;
            width: 100%;
            @extend .Outfit-Regular;

            abbr {
                text-decoration: none;
            }
        }

        .react-calendar__tile{
            &:disabled{
                abbr{
                    color:$color-breadcrumb-grey;
                }
            }
        }

        .react-calendar__tile--now {
            background-color: transparent;
            color: $color-black;

            abbr {
                color: $color-black;
            }
        }

        .react-calendar__tile--now:enabled:hover {
            background-color: #e6e6e6;
            color: $color-white;
        }

        .react-calendar__navigation__label__labelText {
            &.react-calendar__navigation__label__labelText--from {
                font-weight: 600;
                @extend .Nord-Regular;
                margin-left: 11px;
            }
        }

        .react-calendar__navigation button:disabled {
            background-color: transparent;
            color: $color-black
        }

        .react-calendar__navigation button:enabled:hover,
        .react-calendar__navigation button:enabled:focus {
            background-color: transparent;
        }

        .react-calendar__navigation__label {
            flex-grow: unset !important;
        }

        .react-calendar__navigation {
            position: relative;

            .react-calendar__navigation__arrow {
                &.react-calendar__navigation__prev-button {
                    position: absolute;
                    right: 40px;
                    top: 13px;
                }

                &.react-calendar__navigation__next-button {
                    position: absolute;
                    right: -26px;
                    top: 13px;
                    text-align: start;
                }

                &.react-calendar__navigation__prev2-button,
                &.react-calendar__navigation__next2-button {
                    display: none;
                }
            }
        }

        .react-calendar__month-view__weekdays {
            abbr {
                font-weight: 600;
                font-size: 14px;
                color: $color-dark-grey;
                @extend .Outfit-Regular;
            }
        }

        .react-calendar__month-view__days {
            button {
                padding: 15px 0;
            }

            .react-calendar__tile {
                &:hover {
                    border-radius: 50%;
                }

                &:disabled {
                    background-color: transparent;
                }
            }

            .react-calendar__month-view__days__day--weekend {
                color: $color-black;
            }

            .react-calendar__month-view__days__day--neighboringMonth {
                color: $color-dark-grey !important;
                font-weight: 300;
            }

            .react-calendar__tile--active {
                background-color: none;
                background: transparent;
                position: relative;
                overflow: hidden;

                abbr {
                    background: transparent;
                    position: relative;
                    overflow: hidden;
                    color: $color-white;
                    z-index: 99;

                    &:after {
                        content: "";
                        position: absolute;
                        background: $color-black;
                        width: 40px;
                        height: 40px;
                        top: 50%;
                        left: 50%;
                        border-radius: 100%;
                        transform: translate(-49%, -55%);
                        z-index: -1;
                    }
                }
            }

            .theme-popup.month-filter-popup .react-calendar__month-view__days .react-calendar__tile--now {
                background-color: $color-black;
                border-radius: 50%;
            }
        }

        .react-calendar__tile:enabled:hover,
        .react-calendar__tile:enabled:focus {
            background-color: transparent;
        }

        .time-picker-wrapper {
            .ampm-box {
                height: 37px;
                padding: 0.25rem 0.25rem;
                background-color: $color-bg;
                border-radius: 5px;

                label {
                    padding: 0.25rem 1rem;
                    border-radius: 5px;
                    @extend .Nord-Regular;
                    font-weight: 500;
                    font-size: 14px;
                }

                label:has(input:checked) {
                    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
                    background-color: $color-white;
                    font-weight: 600;
                }
            }

            .time-box {
                height: 37px;
                padding: 0.25rem 0.25rem;
                background-color: $color-bg;
                border-radius: 5px;
            }

            .form-select {
                background-color: $color-bg;
                background-image: unset;
                padding: 0 3px;
                border: none;
                outline: none;
                box-shadow: none;
                @extend .Nord-Regular;
                font-weight: 600;
                font-size: 14px;

                &:focus {
                    border: none;
                    outline: none;
                    box-shadow: none;
                }
            }
        }
    }

    &.topup-popup,
    &.domain-verification-complete-popup,
    &.change-domain-popup,
    &.add-bank-account-popup,
    &.withdrawl-popup,
    &.add-folder-popup,
    &.block-alert-popup,
    &.unblock-alert-popup,
    &.delete-account-popup,
    &.delete-comment-popup,
    &.logout-popup {
        .modal-dialog {
            max-width: 525px;
        }

        .modal-body {
            padding: 0;
        }

        .wrapper {
            padding: 30px 35px;

            p {
                color: $color-black;
            }
        }
    }

    &.business-hours-popup {
        .content-container {
            h3 {
                font-size: 14px;
            }

            .react-datepicker-time__header {
                font-size: 12px;
            }

            input {
                border-radius: 5px;
                outline: none;
                border: 1px solid $color-black;
                padding: 10px;
                @extend .Outfit-Regular;
            }
        }

        .btn-solid {
            &:disabled {
                background-color: $color-dark-grey;
            }
        }
    }

    &.img-crop-popup {
        .content-container {
            max-height: 350px;
            overflow: hidden;
        }

        .cropper-container {
            width: 100%;
            height: 300px;
        }
    }

    &.delete-account-popup {
        label {
            font-size: 14px;

            span {
                font-size: 14px;
                color: $color-blue;
            }
        }

        .form-control {
            padding: 16px;
        }
    }

    &.topup-popup {
        .img-wrapper {
            width: 60px;
            height: 60px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .title {
            font-size: 16px;
            font-weight: 500;
        }

        .stats-card {
            border: 1px solid $color-border;
            border-radius: 15px;
            overflow: hidden;

            .wrapper {
                background-color: white;
                padding: 0 !important;
            }
        }
    }
}

.search-popup {
    .modal-body {
        padding: 0;
    }

    .form-control {
        &:disabled {
            background-color: transparent;
        }
    }

    .modal-content {
        background-color: transparent;
    }

    .search-wrapper {
        .time-icon-button {
            margin-left: 20px;
            cursor: pointer;
            background: transparent;
            padding: 0;
            width: auto;
            transform: translateY(-3px);
        }

        .search-box {
            height: 75px;
            padding: 20px 27px;
            border-radius: 10px;
            background-color: $color-white;
            box-shadow: 0px 4px 15px 0px #00000040;

            .form-control {
                height: 100%;
                padding: 0 45px !important;
                border: none;
                background-image: url("data:image/svg+xml,%0A%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.82988 19.5693C4.59542 19.5693 0.33374 15.2902 0.33374 10.0344C0.33374 4.77859 4.59542 0.499512 9.82988 0.499512C15.0643 0.499512 19.326 4.77859 19.326 10.0344C19.326 15.2902 15.0643 19.5693 9.82988 19.5693ZM9.82988 1.89486C5.35511 1.89486 1.72342 5.55069 1.72342 10.0344C1.72342 14.5182 5.35511 18.174 9.82988 18.174C14.3046 18.174 17.9363 14.5182 17.9363 10.0344C17.9363 5.55069 14.3046 1.89486 9.82988 1.89486Z' fill='%23515151'/%3E%3Cpath d='M19.5576 20.4996C19.3816 20.4996 19.2056 20.4345 19.0666 20.2949L17.2137 18.4345C16.945 18.1647 16.945 17.7182 17.2137 17.4484C17.4824 17.1787 17.9271 17.1787 18.1958 17.4484L20.0487 19.3089C20.3173 19.5787 20.3173 20.0252 20.0487 20.2949C19.9097 20.4345 19.7337 20.4996 19.5576 20.4996Z' fill='%23515151'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-position-y: 6px;

                &:hover,
                &:focus {
                    border: none;
                }
            }

            .btn-solid {
                padding: 11px 30px;
                font-size: 14px;
            }
        }

        .suggestion-box {
            padding: 20px 0 0 0;
            border-radius: 10px;
            background-color: $color-white;
            box-shadow: 0px 4px 15px 0px #00000040;
            margin-top: 10px;

            .title-div {
                h6 {
                    color: $color-dark-grey;
                    font-size: 16px;
                    font-weight: 400;
                    padding-left: 27px;
                    padding-right: 27px;
                }
            }

            .suggest-list {
                padding-left: 27px;
                padding-right: 27px;
                height: 415px;
                overflow-y: auto;
                cursor: pointer;

                &::-webkit-scrollbar {
                    display: none;
                }

                ul {
                    padding: 0;

                    li {
                        list-style: none;
                        padding-bottom: 26px;

                        a {
                            color: unset;
                            text-decoration: none;
                        }

                        h6 {
                            color: $color-black;
                            font-size: 14px;
                            font-weight: 400;
                            @extend .Outfit-Regular;
                        }

                    }
                }
            }

        }
    }
}