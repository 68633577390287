$color-black: #101010;
$color-blue: #1877F2;
$color-white: #FFFFFF;
$color-grey: #EFEFEF;
$color-red: #ED4855;
$color-light-grey: #E0E0E0;
$color-dark-grey: #515151;
$color-deep-grey: #525D59;
$color-breadcrumb-grey: #ADACAC;
$color-bg: #F1F4F3;
$color-bg-light: #F6F6F6;
$color-green: #525D59;
$color-border: #dee2e6;
$color-light-green: #4AC300;
$color-orange: #ff0000f8;
